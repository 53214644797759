/*------
Imports
------*/
@import "../../node_modules/bootstrap/scss/bootstrap";
@import "_vars";

/*------ 
Global HTML
------*/
body {
	background: linear-gradient(0deg, #101010, #000000);
	color: $white;
	min-height: 100vh;
	font-family: 'Avenir Next W01';
	&:before {
		content: "";
		display: block;
		position: fixed;
		width: 100vw;
		height: 100vh;
		background-size: cover;
		background-image: url('bigstock--144180284--web.jpg');
		opacity: .1;
	}
	a {
		color: $white;
		text-decoration: underline;
		&:hover {
			color: $white;
		}
	}
}
hr {
	border-color: rgba(255,255,255,.3);
}
main {
	padding-bottom: 10rem;
}
.u-press {
	text-transform: uppercase;
	letter-spacing: 0.1em;
}
.u-tagline {
	font-size: 3rem;
	font-weight: bold;
	line-height: 1.2em;
}
.c-header {
	padding-top: 2rem;
	padding-bottom: 2rem;
}
.c-branding {
	max-width: 12.5rem;
}
.c-body {
	p {
		font-size: 1.1rem;
		line-height: 1.6em;
	}
	p.lead {
		font-size: 1.25rem;
	}
	li {
		font-size: 1.1rem;
	}
}
.c-footer {
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	background-color: #131157;
	padding-top: 1.5rem;
	padding-bottom: 1.5rem;
}
.c-alt-logo {
	&__primary {
		font-size: 3rem;
		font-weight: bold;
		letter-spacing: .1rem;
	}
	&__secondary {
		font-size: 1.15rem;
	}
}